<template>
  <div>
    <CustomerNavBar />

    <b-card>
      <div class="pr-5 pl-5">
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active">
              <div class="mt-2 pt-75">
                <div class="media mb-2">
                  <div class="media-aside align-self-start">
                    <span
                      class="b-avatar badge-light-danger rounded"
                      style="width: 90px; height: 90px"
                      ><span class="b-avatar-img"
                        ><img src="/img/1.9cba4a79.png" alt="avatar" /></span
                      ><!----></span
                    >
                  </div>
                  <div class="media-body">
                    <h4 class="mb-1">Selina Kyle</h4>
                    <b-row class="ml-1">
                      <b-col style="margin-right:1%">
                        <button type="button" class="btn btn-primary">
                          <input type="file" class="d-none" /><span
                            class="d-none d-sm-inline"
                            >Changer </span
                          ><feather-icon icon="EditIcon" />
                        </button>
                      </b-col>
                      <b-col>
                        <button type="button" class="btn btn-outline-secondary">
                          <span class="d-none d-sm-inline">Supprimer </span
                          ><feather-icon icon="TrashIcon" />
                        </button>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <form class="">
                  <div class="row">
                    <div class="col-md-4 col-12">
                      <div role="group" class="form-group">
                        <label for="name">Nom</label>
                        <div>
                          <input
                            id="name"
                            type="text"
                            class="form-control"
                          /><!----><!----><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div role="group" class="form-group">
                        <label for="lastname" class="d-block">Prénom(s)</label>
                        <div>
                          <input
                            id="lastname"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div role="group" class="form-group">
                        <label for="adress" class="d-block">Adresse</label>
                        <div>
                          <input
                            id="adress"
                            type="text"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div role="group" class="form-group">
                        <label for="email" class="d-block">Email</label>
                        <div>
                          <input id="email" type="email" class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div role="group" class="form-group">
                        <label for="phone" class="d-block">Téléphone</label>
                        <div>
                          <input id="phone" type="number" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <button
                  type="button"
                  class="btn mb-1 mb-sm-0 mr-0 mr-sm-1 btn-primary btn-block"
                >
                  Sauvegarder</button
                ><button
                  type="reset"
                  class="btn btn-outline-secondary btn-block"
                >
                  Annuler
                </button>
              </div>
            </div>

            <!---->
          </div>
        </div>
      </div>
    </b-card>
    <div class="fixed-bottom pl-2" style="margin-top: 8%">
      <app-footer />
    </div>
  </div>
</template>

<script>
import CustomerNavBar from "./components/CustomerNavBAr.vue";
export default {
  components: {
    CustomerNavBar,
  },
};
</script>


<style>
@media only screen and (max-width: 600px) {
  .bouton {
    margin-top: 2%;
  }
}
</style>
